.flex-containerH {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 5px;
}
.flex-containerTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 0px 0px;
}
.flex-containerH2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 10px 5px 20px;
}
.flex-containerH3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0px 5px 0px;
}
.flex-containerV {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 5px;
}
.flex-container-right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 0px 10px 10px;
}
.flex-item1 {
  margin: 5px;
}
.flex-item2 {
  margin: 5px 10px 5px 10px;
}
.flex-item3 {
  margin: 10px;
}
.flex-item4 {
  margin: 5px 0px 5px 10px;
}
.flex-switch1 {
  margin: 15px 0px 5px 15px;
}

.text-title {
  margin: 5px 0px 5px 25px;
  font-size: 20px;
}
.text-subtitle {
  margin: 5px 0px 5px 25px;
  font-size: 17px;
}
